'use strict';

(function ($) {
  $('document').ready(function () {

    $('.main-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: false,
      dots: true,
      arrows: false
    });

    $('.popular__slider').slick({

      centerPadding: '60px',
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      autoplaySpeed: 2000,
      slidesToShow: 5,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      }, {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }]
    });

    $('.product__slider').slick({
      // centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrows: true
    });

    $('#zoom').on('click', function () {
      if (!$(this).hasClass('active')) {
        $(this).addClass('active');
        $('.product__slide').zoom();
      } else {
        $(this).removeClass('active');
        $('.product__slide').trigger('zoom.destroy');;
      }
    });
  });
})(jQuery);